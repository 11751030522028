import "./UserHome.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { APIEndPoints } from "../../common/APIEndPoints";
import { PageName } from "../../PageName";
// import SurveyListItem from "../Surveys/SurveyListItem";
import AppPanel from "../AppPanel/AppPanel";
import SurveyRecord from "../../common/SurveyRecord";
import { AppName } from "../../common/AppName";

interface UserHomeProps {
  serverURL: string;
  email: string | undefined;
  role: string | undefined;
  consentToContact: boolean;
  setConsentToContact: (consent: boolean) => void;
  jwt: string | undefined;
  setPage: (pageName: PageName) => void;
  // surveyRecords: SurveyRecord[];
  // setSurveyRecords: (records: SurveyRecord[]) => void;
  setFocusSurveyRecord: (record: SurveyRecord) => void;
}

const MIN_DAYS = 7;

interface PanelState {
  text: string;
  enabled: boolean;
}

export default function UserHome(props: UserHomeProps): JSX.Element | null {
  const [surveyRecords, setSurveyRecords] = useState<SurveyRecord[]>([]);
  const [isUsabilitySurveyComplete, setIsUsabilitySurveyComplete] =
    useState(false);

  let panelStateA0: PanelState = {
    text: "Begin First Rating",
    enabled: true,
  };
  let panelStateA1: PanelState = {
    text: "First Rating Required",
    enabled: false,
  };
  let panelStateB0: PanelState = {
    text: "Begin First Rating",
    enabled: true,
  };
  let panelStateB1: PanelState = {
    text: "First Rating Required",
    enabled: false,
  };

  // Whenever this page loads, fetch all of the current user's survey records
  useEffect(() => {
    // If we're not logged in, abort
    if (!props.jwt) {
      return;
    }
    console.log(props.serverURL + APIEndPoints.usabilityIndividual);
    axios
      .get(props.serverURL + APIEndPoints.usabilityIndividual, {
        headers: { jwt: props.jwt },
      })
      .then((response) => {
        // If user completed usability survey, set flag
        console.log(response.data);
        if (!!response.data) {
          setIsUsabilitySurveyComplete(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    // Fetch every survey for this user
    const cancelSource = axios.CancelToken.source();
    axios
      .get(props.serverURL + APIEndPoints.surveys, {
        headers: { jwt: props.jwt },
        cancelToken: cancelSource.token,
      })
      .then((res) => {
        // If our response contains surveys
        if (!!res.data) {
          const allSurveys = res.data as SurveyRecord[];
          // props.setSurveyRecords(allSurveys);
          setSurveyRecords(allSurveys);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    return () => {
      cancelSource.cancel();
    };
  }, [props.jwt, props.serverURL, props]);

  // // App A Panel States
  // useEffect(() => {
  //   // If the first App A rating exists...
  //   if (!!recordA0) {
  //     // ... but the first rating isn't yet complete
  //     if (!SurveyRecord.isComplete(recordA0)) {
  //       setPanelStateA0({ text: "Continue First Rating", enabled: true });
  //     } else {
  //       // ... and the first rating is complete
  //       setPanelStateA0({ text: "Review First Rating", enabled: true });
  //       setPanelStateA1({ text: "Begin Second Rating", enabled: true });
  //     }
  //   }

  //   // If the second App A rating exists...
  //   if (!!recordA1) {
  //     if (!SurveyRecord.isComplete(recordA1)) {
  //       setPanelStateA1({
  //         text: "Continue Second Rating",
  //         enabled: true,
  //       });
  //     } else {
  //       setPanelStateA1({ text: "Review Second Rating", enabled: true });
  //     }
  //   }
  // }, [recordA0, recordA1]);

  // // // App B Panel States
  // useEffect(() => {
  //   // If the first App B rating exists...
  //   if (!!recordB0) {
  //     // ... but the first rating isn't yet complete
  //     if (!SurveyRecord.isComplete(recordB0)) {
  //       setPanelStateB0({ text: "Continue First Rating", enabled: true });
  //     } else {
  //       // ... and the first rating is complete
  //       setPanelStateB0({ text: "Review First Rating", enabled: true });
  //       setPanelStateB1({ text: "Begin Second Rating", enabled: true });
  //     }
  //   }

  //   // If the second App B rating exists...
  //   if (!!recordB0) {
  //     if (!SurveyRecord.isComplete(recordB0)) {
  //       setPanelStateB1({
  //         text: "Continue Second Rating",
  //         enabled: true,
  //       });
  //     } else {
  //       setPanelStateB1({ text: "Review Second Rating", enabled: true });
  //     }
  //   }
  // }, [recordB0, recordB1]);

  // useEffect(() => {
  //   setRecordA0(
  //     props.surveyRecords.find(
  //       (item) =>
  //         item.appName === AppName.Breathe2Relax &&
  //         item.ratingIteration === 0
  //     )
  //   );
  //   setRecordA1(
  //     props.surveyRecords.find(
  //       (item) =>
  //         item.appName === AppName.Breathe2Relax &&
  //         item.ratingIteration === 1
  //     )
  //   );
  //   setRecordB0(
  //     props.surveyRecords.find(
  //       (item) =>
  //         item.appName === AppName.Calm && item.ratingIteration === 0
  //     )
  //   );
  //   setRecordB1(
  //     props.surveyRecords.find(
  //       (item) =>
  //         item.appName === AppName.Calm && item.ratingIteration === 1
  //     )
  //   );
  // }, [props.surveyRecords])

  if (isUsabilitySurveyComplete) {
    props.setPage(PageName.appreciation);
  }

  const recordA0 = surveyRecords.find(
    (item) =>
      item.appName === AppName.Breathe2Relax && item.ratingIteration === 0
  );
  const recordA1 = surveyRecords.find(
    (item) =>
      item.appName === AppName.Breathe2Relax && item.ratingIteration === 1
  );
  const recordB0 = surveyRecords.find(
    (item) => item.appName === AppName.Calm && item.ratingIteration === 0
  );
  const recordB1 = surveyRecords.find(
    (item) => item.appName === AppName.Calm && item.ratingIteration === 1
  );

  // If the first App A rating exists...
  if (!!recordA0) {
    // ... but the first rating isn't yet complete
    if (!SurveyRecord.isComplete(recordA0)) {
      panelStateA0 = { text: "Continue First Rating", enabled: true };
    } else {
      // ... and the first rating is complete
      panelStateA0 = { text: "First Rating Complete", enabled: false };
      const daysBeforeSecondSurvey = SurveyRecord.calculateDayDiff(recordA0);

      if (daysBeforeSecondSurvey < MIN_DAYS) {
        // ... but 7 days have not passed
        panelStateA1 = {
          text: `${
            MIN_DAYS - daysBeforeSecondSurvey + 1
          } days Before Second Rating`,
          enabled: false,
        };
      } else {
        // ... and 7 days have passed
        panelStateA1 = { text: "Begin Second Rating", enabled: true };
      }
    }
  }

  // If the second App A rating exists...
  if (!!recordA1) {
    if (!SurveyRecord.isComplete(recordA1)) {
      panelStateA1 = {
        text: "Continue Second Rating",
        enabled: true,
      };
    } else {
      panelStateA1 = { text: "Second Rating Complete", enabled: false };
    }
  }

  // If the first App B rating exists...
  if (!!recordB0) {
    // ... but the first rating isn't yet complete
    if (!SurveyRecord.isComplete(recordB0)) {
      panelStateB0 = { text: "Continue First Rating", enabled: true };
    } else {
      // ... and the first rating is complete
      panelStateB0 = { text: "First Rating Complete", enabled: false };
      const daysBeforeSecondSurvey = SurveyRecord.calculateDayDiff(recordB0);

      if (daysBeforeSecondSurvey < MIN_DAYS) {
        // ... but 7 days have not passed
        panelStateB1 = {
          text: `${
            MIN_DAYS - daysBeforeSecondSurvey + 1
          } days Before Second Rating`,
          enabled: false,
        };
      } else {
        // ... and 7 days have passed
        panelStateB1 = { text: "Begin Second Rating", enabled: true };
      }
    }
  }

  // If the second App B rating exists...
  if (!!recordB1) {
    if (!SurveyRecord.isComplete(recordB1)) {
      panelStateB1 = {
        text: "Continue Second Rating",
        enabled: true,
      };
    } else {
      panelStateB1 = { text: "Second Rating Complete", enabled: false };
    }
  }

  // If all four ratings are complete...
  if (
    SurveyRecord.isComplete(recordA0) &&
    SurveyRecord.isComplete(recordA1) &&
    SurveyRecord.isComplete(recordB0) &&
    SurveyRecord.isComplete(recordB1)
  ) {
    // ...move to the Usability Survey pages
    props.setPage(PageName.usability);
  }

  function handleSurveyButtonClicked(appName: AppName, ratingIteration: 0 | 1) {
    console.log(`Survey Button clicked: ${appName} ${ratingIteration}`);

    // Has the user already started rating this app / this iteration?
    let existingRecord = surveyRecords.find(
      (item) =>
        item.appName === appName && item.ratingIteration === ratingIteration
    );
    if (existingRecord) {
      props.setFocusSurveyRecord(existingRecord);
      props.setPage(PageName.survey);
      return;
    }

    // Otherwise, create a new survey record from scratch
    let record = new SurveyRecord();
    record.appName = appName;
    record.ratingIteration = ratingIteration;

    // Create a new survey record on the server
    axios
      .post(props.serverURL + APIEndPoints.survey, record, {
        headers: { jwt: props.jwt },
      })
      .then((response) => {
        props.setFocusSurveyRecord(response.data);
        // Move to survey page
        props.setPage(PageName.survey);
      })
      .catch((error: any) => {
        console.error(error);
      });
  }

  return (
    <div className="user_home">
      {/* <div className="card">
        <h2>User Info</h2>
        <div>Email: {props.email}</div>
        <div>Role: {props.role}</div>
      </div> */}

      <div className="header">
        <h1>Alberta Rating Index for Apps</h1>
        <div>Please go through the apps and complete each survey</div>
      </div>

      <div id="app_rating_dialog" className="card">
        <AppPanel
          appName={AppName.Breathe2Relax}
          iconPath="img/breathe2relax_icon.webp"
          appDescription="Breathe2Relax is a portable stress management tool which provides
              detailed information on the effects of stress on the body and
              instructions and practice exercises to help users learn the stress
              management skill called diaphragmatic breathing. The app is available on Google Play store for Android phones and Apple iTunes for Apple devices."
          appStoreURL="https://apps.apple.com/us/app/breathe2relax/id425720246"
          playStoreURL="https://play.google.com/store/apps/details?id=org.t2health.breathe2relax&hl=en_US&gl=US"
          surveyRecord0={recordA0}
          surveyRecord1={recordA1}
          firstButtonEnabled={panelStateA0.enabled}
          firstButtonText={panelStateA0.text}
          secondButtonEnabled={panelStateA1.enabled}
          secondButtonText={panelStateA1.text}
          handleSurveyButtonClicked={handleSurveyButtonClicked}
        />
        <AppPanel
          appName={AppName.Calm}
          iconPath="img/calm_icon.webp"
          appDescription='Calm is an app the claims to help users "improve sleep quality, reduce anxiety, and improve focus." The app is available on both Google Play store and Apple iTunes. Please use the free trial period for this study. Do not purchase the full version for this study.'
          appStoreURL="https://apps.apple.com/ca/app/calm-sleep-meditate-relax/id571800810"
          playStoreURL="https://play.google.com/store/apps/details?id=com.calm.android"
          surveyRecord0={recordB0}
          surveyRecord1={recordB1}
          firstButtonEnabled={panelStateB0.enabled}
          firstButtonText={panelStateB0.text}
          secondButtonEnabled={panelStateB1.enabled}
          secondButtonText={panelStateB1.text}
          handleSurveyButtonClicked={handleSurveyButtonClicked}
        />
      </div>

      {/* <div
        style={{
          margin: "2em",
          padding: "2em",
          borderRadius: "1em",
          border: "2px grey solid",
        }}
      >
        <label htmlFor="email_consent_checkbox">
          <input
            id="email_consent_checkbox"
            type="checkbox"
            style={{ margin: "0.5em" }}
            checked={props.consentToContact}
            onChange={handleConsentChanged}
          />
          I consent to receiving emails in future updating me about the progress
          of this study.
        </label>
      </div> */}

      {/* <button onClick={handleNewSurveyClicked}>New Survey</button> */}
    </div>
  );
}
