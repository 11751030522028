import "./ChangePassword.css";
import { PageName } from "../../PageName";
import { useState } from "react";
import { useHistory } from "react-router-dom"
import axios from "axios";
import { APIEndPoints } from "../../common/APIEndPoints";
import { useParams } from "react-router-dom";
import { StatusCodes } from "http-status-codes";

interface PasswordResetProps {
  setPage: (pageName: PageName) => void;
  serverURL: string;
}

export default function ChangePassword(props: PasswordResetProps) {
  const { resetToken } = useParams<{ resetToken: string }>();
  const [password, setPassword] = useState("");
  const [passwordVerify, setPasswordVerify] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isPending, setIsPending] = useState(false);
  const history = useHistory()

  const resetPassword = async () => {
    if (password !== passwordVerify) {
      setPasswordError("Passwords do not match!");
      return;
    } else if (password.length < 6) {
      setPasswordError("Password must be greater than 6 characters");
      return;
    } else {
      setPasswordError("");
    }

    setIsPending(true);

    try {
      const requestURL = props.serverURL + APIEndPoints.changePassword;
      const payload = {
        newPassword: password,
        resetToken: resetToken
      }
      const res = await axios.put(requestURL, payload);
      if (res.status === StatusCodes.OK) {
        alert(res.data);
        props.setPage(PageName.login);
        history.push("/");
      }
    } catch (error: any) {
      setPasswordError('Link has expired. Please issue a new forgot password link.')
    }

    setIsPending(false);
  };

  return (
    <div id="change_password">
      <div className="card">
        <h1>Change Password</h1>
        <p>
          You are changing the password for your account. Please enter your new
          password below to regain access!
        </p>
        <input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          placeholder="Password"
        />
        <div className="errorText">{passwordError}</div>
        <br />
        <input
          value={passwordVerify}
          onChange={(e) => setPasswordVerify(e.target.value)}
          type="password"
          placeholder="Verify your Password"
        />
        <div className="errorText">{passwordError}</div>
        <br />
        <button
          onClick={resetPassword}
          disabled={!password || !passwordVerify || isPending}
          className="basic_button"
        >
          Reset Password
        </button>
      </div>
    </div>
  );
}
