export enum PageName {
    welcome = "welcome",
    about = "about",
    login = "login",
    register = "register",
    passwordreset = "passwordreset",
    userhome = "userhome",
    admin = "admin",
    survey = "survey",
    consent = "consent",
    appreciation = "appreciation",
    usability = "usability",
    demographics = "demographics",
    ratings = "ratings"
}