import SurveyRecord from "../../common/SurveyRecord";
import "./SurveyRecordListEntry.css";

interface SurveyRecordListEntryProps {
  surveyRecord: SurveyRecord;
  userEmail: string;
  requestDeleteSurveyRecord: (record: SurveyRecord) => void;
}

export default function SurveyRecordListEntry(
  props: SurveyRecordListEntryProps
) {
  return (
    <div className="survey_record_list_entry">
      <div>{props.userEmail}</div>
      <div>{props.surveyRecord?.userID}</div>
      <div>{props.surveyRecord?._id}</div>
      <div>{props.surveyRecord?.appName}</div>
      <button onClick={() => props.requestDeleteSurveyRecord(props.surveyRecord)}>Delete</button>
    </div>
  );
}
