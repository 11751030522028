import "./AdminHome.css";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { APIEndPoints } from "../../common/APIEndPoints";
import UserListEntry from "./UserListEntry";
import SurveyRecord from "../../common/SurveyRecord";
import SurveyRecordListEntry from "./SurveyRecordListEntry";
import UsabilitySurveyRecord from "../../common/UsabilitySurveyRecord";
import UsabilitySurveyRecordListEntry from "./UsabilitySurveyRecordListEntry";
interface AdminHomeProps {
  jwt: string | undefined;
  serverURL: string;
}

export default function AdminHome(props: AdminHomeProps) {
  const [userList, setUserList] = useState<any[]>([]);
  const [surveyRecords, setSurveyRecords] = useState<SurveyRecord[]>([]);
  const [usabilityRecords, setUsabilityRecords] = useState<
    UsabilitySurveyRecord[]
  >([]);

  useEffect(() => {
    // User list
    axios
      .get(props.serverURL + APIEndPoints.users, {
        headers: { jwt: props.jwt },
      })
      .then((response) => {
        setUserList(response.data.users);
      })
      .catch((error) => {
        console.error("Error fetching users list: " + error);
      });

    // Survey list
    axios
      .get(props.serverURL + APIEndPoints.surveys, {
        headers: { jwt: props.jwt },
      })
      .then((response) => {
        setSurveyRecords(response.data);
      })
      .catch((error) => {
        console.error("Error fetching survey records: " + error);
      });

    // Usability survey list
    axios
      .get(props.serverURL + APIEndPoints.usability, {
        headers: { jwt: props.jwt },
      })
      .then((response) => {
        setUsabilityRecords(response.data);
      })
      .catch((error) => {
        console.error("Error fetching usability survey records: " + error);
      });
  }, [props.jwt, props.serverURL]);

  function requestDeleteUser(email: string) {
    axios
      .delete(props.serverURL + APIEndPoints.users, {
        headers: { jwt: props.jwt, email: email },
      })
      .then((result) => {
        setUserList(result.data.userRecords);
        setSurveyRecords(result.data.surveyRecords);
        setUsabilityRecords(result.data.usabilityRecords);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function requestDeleteSurveyRecord(record: SurveyRecord) {
    axios
      .delete(props.serverURL + APIEndPoints.survey, {
        headers: { jwt: props.jwt, _id: record._id },
      })
      .then((result) => {
        //let remainingRecords = surveyRecords.filter(item => item._id !== record._id);
        setSurveyRecords(result.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function requestDeleteUsabilitySurveyRecord(record: any) {
    axios
      .delete(props.serverURL + APIEndPoints.usability, {
        headers: { jwt: props.jwt, _id: record._id },
      })
      .then((result) => {
        setUsabilityRecords(result.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <div className="admin_home padded">
      <h1>Admin Home</h1>

      <h2>Users</h2>
      <a href={props.serverURL + APIEndPoints.downloadusers}>
        <button className="download_button basic_button">
          Download User Records
        </button>
      </a>

      <div className="user_table_headings">
        <div>Email</div>
        <div>Database ID</div>
        <div>Role</div>
      </div>
      <div>
        {userList.map((user, index) => {
          return (
            <UserListEntry
              key={user._id}
              email={user.email}
              id={user._id}
              role={user.role}
              requestDeleteUser={requestDeleteUser}
            />
          );
        })}
      </div>

      <h2>Surveys</h2>
      {/* <button className="basic_button" onClick={downloadSurveyRecords}>Download Survey Records</button> */}
      <a href={props.serverURL + APIEndPoints.downloadsurveys}>
        <button className="download_button basic_button">
          Download Survey Records
        </button>
      </a>
      <div className="survey_table_headings">
        <div>User Email</div>
        <div>User ID</div>
        <div>Database ID</div>
        <div>App Name</div>
      </div>
      <div>
        {surveyRecords.map((record, index) => {
          const associatedUser = userList.find(
            (item) => item._id === record.userID
          );
          const userEmail = !!associatedUser ? associatedUser.email : "unknown";
          return (
            <SurveyRecordListEntry
              key={index}
              surveyRecord={record}
              userEmail={userEmail}
              requestDeleteSurveyRecord={requestDeleteSurveyRecord}
            />
          );
        })}
      </div>

      <h2>Usability Surveys</h2>
      <a href={props.serverURL + APIEndPoints.downloadusabilityrecords}>
        <button className="download_button basic_button">
          Download Usability Survey Records
        </button>
      </a>

      <div className="usability_survey_records_table_headings">
        <div>Database ID</div>
        <div>User ID</div>
      </div>
      <div>
        {usabilityRecords.map((record, index) => {
          return (
            <UsabilitySurveyRecordListEntry
              key={"record" + index}
              surveyRecord={record}
              requestDeleteUsabilitySurveyRecord={
                requestDeleteUsabilitySurveyRecord
              }
            />
          );
        })}
      </div>
    </div>
  );
}
