import { useState } from "react";

export default function CaregiverDemographicQuestionnaire() {
  const [age, setAge] = useState("18");
  const [gender, setGender] = useState("");
  const [education, setEducation] = useState("");
  const [appUseFrequency, setAppUseFrequency] = useState("");
  const [appSearchFrequency, setAppSearchFrequency] = useState("");
  const [appAdviceFrequency, setAppAdviceFrequency] = useState("");
  const [appRecommendedFrequency, setAppRecommendedFrequency] = useState("");
  const [factorsConsidered, setFactorsConsidered] = useState("");

  function handleSubmit(){
      console.log(`Submit!`);
  }
    
  return (
    <div id="UserDemographicQuestionnaire">
      <h1>Demographic Questionnaire for Family Caregivers</h1>
      <div
        id="inputs_grid"
        style={{
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          columnGap: "2rem",
        }}
      >
        <label>Age: </label>
        <input id="age_input" type="text" value={age} onChange={(event) => setAge(event.target.value)}/>

        <label>Gender: </label>
        <input id="gender_input" type="text" value={gender} onChange={event => setGender(event.target.value)}/>

        <label>Highest level of education: </label>
        <input id="education_input" type="text" value={education} onChange={event => setEducation(event.target.value)}/>

        <label>How often does your family member use mobile health apps?</label>
        <select id="app_use_frequency_select" value={appUseFrequency} onChange={event => setAppUseFrequency(event.target.value)}>
          <option value="">--- Please choose an option ---</option>
          <option value="never">Never</option>
          <option value="rarely">Rarely</option>
          <option value="often">Often</option>
          <option value="very_often">Very Often</option>
        </select>

        <label>
          How often do you look for information about mobile health
          applications for your family member?
        </label>
        <select id="app_search_frequency_select" value={appSearchFrequency} onChange={event => setAppSearchFrequency(event.target.value)}>
          <option value="">--- Please choose an option ---</option>
          <option value="never">Never</option>
          <option value="rarely">Rarely</option>
          <option value="often">Often</option>
          <option value="very_often">Very Often</option>
        </select>

        <label>
          How often do you ask your health care providers (e.g. your family
          physician, nurse, physiotherapist, or others) about mobile
          health applications for your family member?
        </label>
        <select id="app_advice_frequency_select" value={appAdviceFrequency} onChange={event => setAppAdviceFrequency(event.target.value)}>
          <option value="">--- Please choose an option ---</option>
          <option value="never">Never</option>
          <option value="rarely">Rarely</option>
          <option value="often">Often</option>
          <option value="very_often">Very Often</option>
        </select>

        <label>
          How often do your health care providers (e.g. your family physician,
          nurse, physiotherapist, or others) suggest using a mobile health
          application to you?
        </label>
        <select id="app_recommended_frequency_select" value={appRecommendedFrequency} onChange={event => setAppRecommendedFrequency(event.target.value)}>
          <option value="">--- Please choose an option ---</option>
          <option value="never">Never</option>
          <option value="rarely">Rarely</option>
          <option value="often">Often</option>
          <option value="very_often">Very Often</option>
        </select>

        <label>
          What factors do you consider when you want to choose a mobile health
          application for your family member? (Please note we may use the anonymous quotes in our reports or
          publications.)
        </label>
        <textarea id="factors_considered_text_area" value={factorsConsidered} onChange={event => setFactorsConsidered(event.target.value)}/>
      </div>

      <button id="submit_button" onClick={handleSubmit}>Submit</button>
    </div>
  );
}
