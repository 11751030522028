import "./SystemUsabilitySurvey.css";
import { useEffect, useState } from "react";
import UsabilityQuestion from "./UsabilityQuestion";
import { PageName } from "../../../PageName";
import axios from "axios";
import { APIEndPoints } from "../../../common/APIEndPoints";
// import UsabilitySurveyRecord from "../../../common/UsabilitySurveyRecord";
// import { SentimentNumber } from "../../../common/Sentiments";

interface SurveyProps {
  serverURL: string;
  jwt: string | undefined;
  setPage: (pageName: PageName) => void;
}

export default function SystemUsabilitySurvey(props: SurveyProps) {
  const [hasSeenIntro, setHasSeenIntro] = useState<boolean>(false);
  const [answers, setAnswers] = useState<(number | null)[]>([
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ]);

  var setPage = props.setPage;

  // On load, check if this user has already completed a usability survey
  useEffect(() => {
    axios
      .get(props.serverURL + APIEndPoints.usability, {
        headers: { jwt: props.jwt },
      })
      .then((response) => {
        // If a usability survey record already exists, move on to the appreciation page
        if (!!response.data) {
          setPage(PageName.appreciation);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [props.jwt, props.serverURL, setPage]);

  // Submit usability survey to the server
  async function handleSubmit() {
    // Ensure that there are answers to every question
    if (answers.some((value) => value === null)) {
      console.error("Missing some usability survey answers. Cannot submit.");
      return;
    }

    try {
      /*const response =*/ await axios.post(
        props.serverURL + APIEndPoints.usability,
        answers,
        { headers: { jwt: props.jwt } }
      );

      // If successful, move to appreciation page
      props.setPage(PageName.appreciation);
    } catch (error: unknown) {
      console.error(error);
    }
  }

  let questionTexts = [
    "I think that I would like to use this website frequently.",
    "I found the website unnecessarily complex.",
    "I thought the website was easy to use.",
    "I think I would need the support of a technical person to be able to use this website.",
    "I found the various functions in this website were well integrated.",
    "I thought there was too much inconsistency in this website.",
    "I would imagine that most people would learn to use this website very quickly.",
    "I found the website very cumbersome to use.",
    "I felt very confident using the website.",
    "I needed to learn a lot of things before I could get going with this system.",
  ];

  function handleChoiceChanged(questionIndex: number, choice: number) {
    const newAnswers = [...answers];
    newAnswers[questionIndex] = choice;
    setAnswers(newAnswers);
  }

  function isSubmitDisabled(): boolean | undefined {
    return answers.some((value) => value === null);
  }

  let submitStatus = isSubmitDisabled();

  return (
    <div className="system_usability_survey">
      {!hasSeenIntro && (
        <div className="usability_intro">
          <div>
            Thank you for participating in our study. We would like to know
            about your experience using the ARIA website. We would appreciate it
            if you would complete our 10 question survey next. When answering
            the survey, please consider your whole experience on this website,
            including the questionnaires.
          </div>
          <button
            className="basic_button"
            onClick={() => setHasSeenIntro(true)}
          >
            GO TO SURVEY
          </button>
        </div>
      )}
      {hasSeenIntro && (
        <div>
          <h1>System Usability Survey</h1>
          <p>
            Please choose the most suitable answer to each following statement
          </p>

          <div className="card">
            <div className="questions">
              {questionTexts.map((questionText, index) => {
                return (
                  <UsabilityQuestion
                    key={"usability_question_" + index}
                    chosenIndex={answers[index]}
                    questionText={questionText}
                    choiceIndexChanged={(choice) =>
                      handleChoiceChanged(index, choice)
                    }
                  />
                );
              })}
            </div>
            <div className="button">
              <button
                className="basic_button"
                onClick={handleSubmit}
                disabled={submitStatus}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
