// import { SurveyQuestionData } from "./SurveyQuestionData"
import { ObjectId } from "mongodb";
import { AppName } from "./AppName";
import { SentimentNumber } from "./Sentiments";

export default class SurveyRecord {
  _id: ObjectId | null = null;
  userID: string | null = null;
  appName: AppName.Breathe2Relax | AppName.Calm | null = null;
  ratingIteration: 0 | 1 | null = null;
  hasUsedAppBefore: boolean | null = null;
  answers: (SentimentNumber | null)[] = [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null, //19th - Overall
  ];
  overall: 1 | 2 | 3 | 4 | 5 | null = null;
  lastUpdated: Date | null = null;

  public static sumScores(record: SurveyRecord): number {
    let sum = 0;
    record.answers.forEach((item) => {
      if (!!item) {
        sum += item;
      }
    });
    return sum;
  }

  public static maxScore(record: SurveyRecord): number {
    return 4 * record.answers.length;
  }

  public static isComplete(record: SurveyRecord | undefined): boolean {
    // Early out for undefined records
    if (record === undefined) return false;

    // Test that all of the relevant data fields are present
    if (record.userID === null) return false;
    if (record.appName === null) return false;
    if (record.hasUsedAppBefore === null) return false;
    for (let value of record.answers) {
      if (value === null) return false;
    }
    if (record.overall === null) return false;
    return true;
  }

  public static calculateDayDiff(record: SurveyRecord): number {
    const currentDate = new Date();
    const recordDate = new Date(record.lastUpdated as Date);
    const difference = currentDate.getTime() - recordDate.getTime()
    const differenceInDays = Math.ceil(difference / (1000 * 3600 * 24));
    return differenceInDays;
  }
}
