import "./UsabilityQuestion.css";
import RatingScaleInput from "../RatingScaleInput";
import { SentimentNumbers } from "../../../common/Sentiments";

interface UsabilityQuestionProps {
  questionText: string;
  chosenIndex: number | null;
  choiceIndexChanged: (newIndex: number) => void;
}

export default function UsabilityQuestion(props: UsabilityQuestionProps) {
  return (
    <div className="usability_question">
      <div className="question_text">{props.questionText}</div>
      <RatingScaleInput
        ratingOptions={Array.from(SentimentNumbers.values()).map((value) =>
          value.toString()
        )}
        groupName={props.questionText}
        chosenIndex={props.chosenIndex}
        choiceIndexChanged={props.choiceIndexChanged}
      />
      {/* <button onClick={(event) => console.log(`Button clicked: ${props.questionIndex}`)}>Button{props.questionIndex}</button> */}
      <div className="choice_labels">
        <div>Strongly Disagree</div>
        <div></div>
        <div>Neutral</div>
        <div></div>
        <div>Strongly Agree</div>
      </div>
    </div>
  );
}
