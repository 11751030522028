import { AppName } from "../../common/AppName";
import { StarRating } from "../../common/StarRating";
import SurveyRecord from "../../common/SurveyRecord";
import "./AppPanel.css";

interface AppPanelProps {
  appName: AppName;
  iconPath: string;
  appDescription: string;
  appStoreURL: string;
  playStoreURL: string;
  surveyRecord0: SurveyRecord | undefined;
  surveyRecord1: SurveyRecord | undefined;
  firstButtonEnabled: boolean;
  firstButtonText: string;
  secondButtonEnabled: boolean;
  secondButtonText: string;
  handleSurveyButtonClicked: (appName: AppName, ratingIteration: 0 | 1) => void;
}

export default function AppPanel(props: AppPanelProps) {
  return (
    <div className="app_panel">
      {/* <div className="app_info"> */}
      <div className="app_header">
        <img className="app_icon" src={props.iconPath} alt="App icon"></img>
        <h2 className="app_title">{props.appName}</h2>
      </div>
      <p className="app_description">{props.appDescription}</p>
      <div className="app_store_buttons">
        <a
          className="app_button appStore button"
          href={props.appStoreURL}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="img/download_on_the_app_store.png"
            alt="Download on the App Store"
          ></img>
        </a>
        <a
          className="app_button playStore button"
          href={props.playStoreURL}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="img/google-play-badge.png"
            alt="Get it on Google Play"
          ></img>
        </a>
      </div>
      {/* </div> */}

      <h4 className="first rating_title">1st Rating</h4>
      <div className="first stars">{props.surveyRecord0 && props.surveyRecord0.overall != null ? StarRating.get(props.surveyRecord0.overall) : "UNRATED"}</div>
      <button
        disabled={!props.firstButtonEnabled}
        className="first button basic_button"
        onClick={(event) => props.handleSurveyButtonClicked(props.appName, 0)}
      >
        {props.firstButtonText}
      </button>

      <h4 className="second rating_title">2nd Rating</h4>
      <div className="second stars">{props.surveyRecord1 && props.surveyRecord1.overall != null ? StarRating.get(props.surveyRecord1.overall) : "UNRATED"}</div>
      <button
        disabled={!props.secondButtonEnabled}
        className="second button basic_button"
        onClick={(event) => props.handleSurveyButtonClicked(props.appName, 1)}
      >
        {props.secondButtonText}
      </button>
    </div>
  );
}
