import "./SurveyTool.css";
import axios, { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { APIEndPoints } from "../../common/APIEndPoints";
import { ARIASurveyContent } from "../../common/ARIASurveyContent";
import { RoleName } from "../../common/RoleName";
import {
  //SentimentLabels,
  SentimentNumber,
  SentimentNumbers,
} from "../../common/Sentiments";
// import { Sentiments } from "../../common/Sentiments";
// import { SurveyQuestionData } from "../../common/SurveyQuestionData";
import SurveyRecord from "../../common/SurveyRecord";
import { PageName } from "../../PageName";
import RatingScaleInput from "./RatingScaleInput";
import { AnswerType } from "../../common/AnswerType";
import { StarRating } from "../../common/StarRating";

interface SurveyToolProps {
  serverURL: string;
  jwt: string | undefined;
  userRole: RoleName;
  surveyRecord: SurveyRecord;
  setSurveyRecord: (newRecord: SurveyRecord) => void;
  setPage: (pageName: PageName) => void;
}

export default function SurveyTool(props: SurveyToolProps) {
  const [questionIndex, setQuestionIndex] = useState(0);
  const [chosenIndex, setChosenIndex] = useState<number | null>(null);
  const [isPending, setIsPending] = useState(false);
  //const [chosenResponse, setChosenResponse] = useState("");
  //const [responseArray, setResponseArray] = useState(props.savedResponses);

  useEffect(() => {
    if (!props.surveyRecord) return;

    const answeredQuestions = props.surveyRecord.answers.filter(
      (answer) => answer !== null
    );

    if (answeredQuestions.length > 0) {
      setQuestionIndex(answeredQuestions.length - 1);
    }
  }, [props.surveyRecord]);

  // When the displayed question changes...
  useEffect(() => {
    if (!props.surveyRecord) return;
    // Update the sentiment scale to show whichever sentiment is on record as selected
    setChosenIndex(props.surveyRecord.answers[questionIndex]);
  }, [questionIndex, props.surveyRecord, props.surveyRecord.answers]);

  function previousQuestion() {
    if (questionIndex - 1 >= 0) {
      setQuestionIndex(questionIndex - 1);
    }
  }

  function nextQuestion() {
    if (questionIndex + 1 < ARIASurveyContent.length) {
      setQuestionIndex(questionIndex + 1);
    }
  }

  function saveAndQuitSurvey() {
    const data = props.surveyRecord;
    // PUT the update SurveyRecord to the server
    axios
      .put(props.serverURL + APIEndPoints.survey, data, {
        headers: { jwt: props.jwt },
      })
      .then((response: AxiosResponse) => {
        props.setSurveyRecord(response.data);
        // Return to the user dashboard/home
        props.setPage(PageName.userhome);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function finishSurvey() {
    setIsPending(true);
    // DEBUG: Temporarily fill in the remainder of the survey fields
    const data = props.surveyRecord;
    data.overall = (data.answers[data.answers.length - 1]! + 1) as
      | 1
      | 2
      | 3
      | 4
      | 5;
    data.hasUsedAppBefore = false;

    // PUT the update SurveyRecord to the server
    await axios
      .put(props.serverURL + APIEndPoints.survey, data, {
        headers: { jwt: props.jwt },
      })
      .then((response: AxiosResponse) => {
        props.setSurveyRecord(response.data);
      })
      .catch((error) => {
        console.error(error);
      });

    if (
      props.surveyRecord.ratingIteration === 0 &&
      props.userRole === RoleName.User
    ) {
      // Set cron job to send update email after 7 days
      axios
        .post(props.serverURL + APIEndPoints.scheduleUpdate, {
          appName: props.surveyRecord.appName,
          userID: props.surveyRecord.userID,
        })
        .then(async (response: AxiosResponse) => {
          // Return to the user dashboard/home
          setIsPending(false);
          props.setPage(PageName.userhome);
          let alertMessage = "Congratulations on completing your survey! Your contribution is greatly appreciated!";
          if (props.surveyRecord.ratingIteration === 0) {
            alertMessage += " We kindly request that you complete your second rating after at least 7 days. You will receive an email reminding you when you are eligible to complete the second rating.";
          }
          alert(alertMessage);
        })
        .catch((error) => {
          setIsPending(false);
          console.error(error);
          alert("Something went wrong when sending your follow up email...");
        });
    }
  }

  function handleChosenIndexChanged(newChosenIndex: number) {
    setChosenIndex(newChosenIndex);
    const newAnswers = [...props.surveyRecord.answers];
    newAnswers[questionIndex] = newChosenIndex as SentimentNumber;
    props.setSurveyRecord({ ...props.surveyRecord, answers: newAnswers });
  }

  // Grab the correct survey question phrasing depending on the curret user's role
  const questionContent = ARIASurveyContent[questionIndex];
  let questionText = questionContent.userText.text;
  let subText = questionContent.userText.subtext;
  if (props.userRole !== RoleName.User) {
    questionText = questionContent.providerText.text;
    subText = questionContent.providerText.subtext;
  }
  let questionCategory = questionContent.category;

  //let ratingOptions = SentimentLabels.map((value) => value.toString());
  let ratingOptions = SentimentNumbers.map((value) => value.toString());
  switch (questionContent.answerType) {
    case AnswerType.Stars:
      ratingOptions = Array.from(StarRating.values());
      break;
  }

  return (
    <div className="survey_tool">
      <div className="hero">
        <h1>
          {props.surveyRecord.appName}, Question {questionIndex + 1} of{" "}
          {ARIASurveyContent.length} ({questionCategory})
        </h1>
        <p className="question_text">{questionText}</p>
        {!!subText && <p className="sub_text">{subText}</p>}

        <RatingScaleInput
          ratingOptions={ratingOptions}
          groupName={questionText}
          chosenIndex={chosenIndex}
          choiceIndexChanged={(newIndex) => handleChosenIndexChanged(newIndex)}
        />
        <div className="sentiment_labels">
          <div className="left">Strongly Disagree</div>
          <div className="center">Neutral</div>
          <div className="right">Strongly Agree</div>
        </div>

        <div className="survey_progress_buttons">
          <button
            className="survey_progress_button secondary_button previous"
            disabled={questionIndex === 0}
            onClick={previousQuestion}
          >
            Previous
          </button>

          {/* <button className="survey_progress_button quit">Quit</button> */}
          <button
            className="survey_progress_button  secondary_button save"
            onClick={saveAndQuitSurvey}
          >
            Save & Quit
          </button>

          {questionIndex < ARIASurveyContent.length - 1 && (
            <button
              className="survey_progress_button basic_button next"
              disabled={chosenIndex === null}
              onClick={nextQuestion}
            >
              Next
            </button>
          )}
          {questionIndex === ARIASurveyContent.length - 1 && (
            <button
              className="survey_progress_button basic_button finish"
              disabled={chosenIndex === null || isPending }
              onClick={finishSurvey}
            >
              Finish
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
