import { PageName } from "../../PageName";
import "./WelcomePage.css";

interface WelcomePageProps {
  setPage: (pageName: PageName) => void;
}

export default function WelcomePage(props: WelcomePageProps) {
  return (
    <div id="home_page" className="home_page padded">
      <div className="intro_text">        
        <h1>
          Alberta Rating Index for Apps
        </h1>

        <p>
          There are more than 300,000 mobile health applications available to
          the public.
        </p>
        <p>
          As the sale of mobile health apps is not regulated, there are minimal
          safeguards to protect users from apps with low quality or harmful
          content.
        </p>
        <p>
          We have developed an assessment tool, Alberta Rating Index for Apps
          (ARIA), for users to rate the quality of mobile health apps and made
          it accessible to users through this website.
        </p>

        <div>
          {/* <button
            id="register_button"
            className="basic_button"
            onClick={() => props.setPage(PageName.register)}
          >
            Start Rating
          </button> */}
        </div>
      </div>

    </div>
  );
}
