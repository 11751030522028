import axios from "axios";
import { useState } from "react";
import { APIEndPoints } from "../../common/APIEndPoints";
import { PageName } from "../../PageName";
import "./RegistrationDetails.css";
import { StatusCodes } from "http-status-codes";

interface RegistrationDetailsProps {
  serverURL: string;
  email: string;
  setEmail: (email: string) => void;
  emailError: string;
  setEmailError: (error: string) => void;
  password: string;
  setPassword: (password: string) => void;
  passwordVerify: string;
  setPasswordVerify: (passwordVerify: string) => void;
  passwordError: string;
  setPasswordError: (error: string) => void;
  registrationError: string;
  setRegistrationError: (error: string) => void;

  setDetailsValidated: (detailValidated: boolean) => void;

  setPage: (pageName: PageName) => void;
}
export default function RegistrationDetails(props: RegistrationDetailsProps) {
  let email = props.email;
  let setEmail = props.setEmail;
  let emailError = props.emailError;
  let setEmailError = props.setEmailError;
  let password = props.password;
  let setPassword = props.setPassword;
  let passwordVerify = props.passwordVerify;
  let setPasswordVerify = props.setPasswordVerify;
  let passwordError = props.passwordError;
  let setPasswordError = props.setPasswordError;
  let registrationError = props.registrationError;
  let setRegistrationError = props.setRegistrationError;

  const [pending, setPending] = useState(false);

  async function handleRegisterClicked() {
    // Check whether the entered email/password would be acceptable to the server
    if (password !== passwordVerify) {
      setPasswordError("Passwords do not match!");
      return;
    } else {
      setPasswordError("");
    }

    setPending(true);
    try {
      const requestURL = props.serverURL + APIEndPoints.checkRegistration;

      const ipRespose = await axios.get('https://geolocation-db.com/json/');
      const ip = ipRespose.data.IPv4;

      const res = await axios.get(requestURL, {
        headers: { email, password, ip },
      });
      if (res.status === StatusCodes.OK) {
        setPending(false);
        props.setDetailsValidated(true);
      }
    } catch (error: any) {
      console.error(error);
      setPending(false);
      console.log(error.response.data["ipError"]);
      if (error.response.data["ipError"]) {
        setRegistrationError(error.response.data.ipError);
      }
      if (error.response.status === 400) {
        setEmailError(error.response.data.emailError);
        setPasswordError(error.response.data.passwordError);
      } else {
        console.log(`User details NOT valid`);
      }
    }
  }

  return (
    <div className="registration_details">
      <div className="card">
        <h1>Create your account</h1>

        {/* <p>The study has been paused as we make some changes. New participants are no longer being accepted at this time. Please contact Christine Daum at cdaum@uwaterloo.ca should you have any questions</p> */}

        <input
          className="basic_input"
          type="text"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          placeholder="E-mail"
        ></input>

        <div className="errorText">{emailError}</div>

        <input
          className="basic_input"
          type="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          placeholder="Password"
        ></input>

        <div className="errorText">{passwordError}</div>

        <input
          className="basic_input"
          type="password"
          value={passwordVerify}
          onChange={(event) => setPasswordVerify(event.target.value)}
          placeholder="Verify Your Password"
        ></input>

        <div className="errorText">{passwordError}</div>

        <button
          className="basic_button"
          disabled={!email || !password || !passwordVerify || pending}
          onClick={handleRegisterClicked}
        >
          REGISTER
        </button>

        <div className="errorText">{registrationError}</div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2em",
          }}
        >
          <div className="already_have_account">
            <p>Already have an account?</p>
            <button
              className="link_button"
              onClick={() => {
                props.setPage(PageName.login);
              }}
            >
              Sign In
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
