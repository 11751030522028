import "./DemographicQuestionnaire.css";
import { ChangeEvent, useState } from "react";
import { RoleName } from "../../common/RoleName";
import  DemographicQuestionnaireData  from "../../common/DemographicQuestionnaireData";


interface DemographicQuestionnaireProps {
  role: RoleName;
  handleSubmit: (data: DemographicQuestionnaireData) => void;
}

export default function DemographicQuestionnaire(
  props: DemographicQuestionnaireProps
) {
  let questionText = [
    "How often do you use mobile health apps?",
    "How often do you look for information about mobile health applications?",
    "How often do you ask your health care providers (e.g., your family physician, nurse, physiotherapist, or others) about using a mobile health application?",
    "How often do your health care providers (e.g. your family physician, nurse, physiotherapist, or others) suggest using a mobile health application to you?",
    "What factors do you consider when you want to choose a mobile health app?",
  ];

  if (props.role === RoleName.Caregiver) {
    questionText = [
      "How often does your family member use mobile health apps?",
      "How often do you look for information about mobile health applications for your family member?",
      "How often do you ask your health care providers (e.g. your family physician, nurse, physiotherapist, or others) about mobile health applications for your family member?",
      "How often do your health care providers (e.g. your family physician, nurse, physiotherapist, or others) suggest using a mobile health application to you?",
      "What factors do you consider when you want to choose a mobile health application for your family member?",
    ];
  }

  if (props.role === RoleName.Provider) {
    questionText = [
      "How often do you use mobile health apps for your clients/patients?",
      "How often do you look for information about mobile health applications for your clients/patients?",
      "How often are you asked about health mobile apps by your clients/patients?",
      "How often do you suggest using mobile health applications to your clients/patients?",
      "What factors do you consider when you choose a mobile health app for your clients/patients?",
    ];
  }

  const [age, setAge] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [education, setEducation] = useState<string>("");
  const [occupation, setOccupation] = useState<string>("");
  const [appUseFrequency, setAppUseFrequency] = useState<string>("");
  const [appSearchFrequency, setAppSearchFrequency] = useState<string>("");
  const [appAdviceFrequency, setAppAdviceFrequency] = useState<string>("");
  const [appRecommendedFrequency, setAppRecommendedFrequency] =
    useState<string>("");
  const [factorsConsidered, setFactorsConsidered] = useState<string>("");

  function handleGenderChange(event: ChangeEvent<HTMLInputElement>) {
    setGender(event.target.value);
  }

  function handleUseFrequencyChange(event: ChangeEvent<HTMLInputElement>) {
    setAppUseFrequency(event.target.value);
  }

  function handleSearchFrequencyChange(event: ChangeEvent<HTMLInputElement>) {
    setAppSearchFrequency(event.target.value);
  }

  function handleAdviceFrequencyChange(event: ChangeEvent<HTMLInputElement>) {
    setAppAdviceFrequency(event.target.value);
  }

  function handleRecommendFrequencyChange(
    event: ChangeEvent<HTMLInputElement>
  ) {
    setAppRecommendedFrequency(event.target.value);
  }

  function isSurveyComplete(): boolean{
    let isComplete = true;
    if(!age) isComplete = false;
    if(!gender) isComplete = false;
    if(props.role === RoleName.Provider && !occupation) isComplete = false;
    if(!appUseFrequency) isComplete = false;
    if(!appSearchFrequency) isComplete = false;
    if(!appAdviceFrequency) isComplete = false;
    if(!appRecommendedFrequency) isComplete = false;

    return isComplete;
  }

  function handleSubmit() {
    let data: DemographicQuestionnaireData = {
      age,
      gender,
      education,      
      appUseFrequency,
      appSearchFrequency,
      appAdviceFrequency,
      appRecommendedFrequency,
      factorsConsidered
    };

    if(!!occupation){
      data = {...data, occupation}
    };

    props.handleSubmit(data);
  }

  return (
    <div className="demographic_questionnaire">
      <h1>Demographic Questionnaire</h1>
      <p>Please fill out the questionnaire below</p>

      <div className="card">
        <div>
          <h3>Age</h3>
          <input
            id="age_input"
            type="number"
            min={0}
            value={age}
            onChange={(event) => setAge(event.target.value)}
          />
        </div>

        <div>
          <h3>Gender</h3>

          <ul>
            <li>
              <label>
                <input
                  type="radio"
                  name="gender"
                  value="male"
                  checked={gender === "male"}
                  onChange={handleGenderChange}
                />
                Male
              </label>
            </li>
            <li>
              <label>
                <input
                  type="radio"
                  name="gender"
                  value="female"
                  checked={gender === "female"}
                  onChange={handleGenderChange}
                />
                Female
              </label>
            </li>
            <li>
              <label>
                <input
                  type="radio"
                  name="gender"
                  value="non-binary"
                  checked={gender === "non-binary"}
                  onChange={handleGenderChange}
                />
                Non-binary
              </label>
            </li>
            <li>
              <label>
                <input
                  type="radio"
                  name="gender"
                  value="transgender"
                  checked={gender === "transgender"}
                  onChange={handleGenderChange}
                />
                Transgender
              </label>
            </li>
            <li>
              <label>
                <input
                  type="radio"
                  name="gender"
                  value="prefer not to say"
                  checked={gender === "prefer not to say"}
                  onChange={handleGenderChange}
                />
                Prefer not to say
              </label>
            </li>
          </ul>
          <label>I self identify as: </label>
          <input
            type="text"
            value={gender}
            onChange={handleGenderChange}
          ></input>
        </div>
        <div>
          <h3>Highest level of education</h3>
          <input
            id="education_input"
            type="text"
            value={education}
            onChange={(event) => setEducation(event.target.value)}
          />
        </div>
        {props.role === RoleName.Provider && (
          <div>
            <h3>Occupation</h3>
            <input
              type="text"
              value={occupation}
              onChange={(event) => setOccupation(event.target.value)}
            />
          </div>
        )}
        <div>
          <h3>{questionText[0]}</h3>
          <label>
            <input
              type="radio"
              name="use_frequency"
              value="Never"
              onChange={handleUseFrequencyChange}
            />
            Never
          </label>
          <label>
            <input
              type="radio"
              name="use_frequency"
              value="Rarely"
              onChange={handleUseFrequencyChange}
            />
            Rarely
          </label>
          <label>
            <input
              type="radio"
              name="use_frequency"
              value="Often"
              onChange={handleUseFrequencyChange}
            />
            Often
          </label>
          <label>
            <input
              type="radio"
              name="use_frequency"
              value="Very Often"
              onChange={handleUseFrequencyChange}
            />
            Very Often
          </label>
        </div>
        <div>
          <h3>{questionText[1]}</h3>
          <label>
            <input
              type="radio"
              name="search_frequency"
              value="Never"
              onChange={handleSearchFrequencyChange}
            />
            Never
          </label>
          <label>
            <input
              type="radio"
              name="search_frequency"
              value="Rarely"
              onChange={handleSearchFrequencyChange}
            />
            Rarely
          </label>
          <label>
            <input
              type="radio"
              name="search_frequency"
              value="Often"
              onChange={handleSearchFrequencyChange}
            />
            Often
          </label>
          <label>
            <input
              type="radio"
              name="search_frequency"
              value="Very Often"
              onChange={handleSearchFrequencyChange}
            />
            Very Often
          </label>
        </div>
        <div>
          <h3>{questionText[2]}</h3>
          <label>
            <input
              type="radio"
              name="advice_frequency"
              value="Never"
              onChange={handleAdviceFrequencyChange}
            />
            Never
          </label>
          <label>
            <input
              type="radio"
              name="advice_frequency"
              value="Rarely"
              onChange={handleAdviceFrequencyChange}
            />
            Rarely
          </label>
          <label>
            <input
              type="radio"
              name="advice_frequency"
              value="Often"
              onChange={handleAdviceFrequencyChange}
            />
            Often
          </label>
          <label>
            <input
              type="radio"
              name="advice_frequency"
              value="Very Often"
              onChange={handleAdviceFrequencyChange}
            />
            Very Often
          </label>
        </div>
        <div>
          <h3>{questionText[3]}</h3>
          <label>
            <input
              type="radio"
              name="recommend_frequency"
              value="Never"
              onChange={handleRecommendFrequencyChange}
            />
            Never
          </label>
          <label>
            <input
              type="radio"
              name="recommend_frequency"
              value="Rarely"
              onChange={handleRecommendFrequencyChange}
            />
            Rarely
          </label>
          <label>
            <input
              type="radio"
              name="recommend_frequency"
              value="Often"
              onChange={handleRecommendFrequencyChange}
            />
            Often
          </label>
          <label>
            <input
              type="radio"
              name="recommend_frequency"
              value="Very Often"
              onChange={handleRecommendFrequencyChange}
            />
            Very Often
          </label>
        </div>
        <div className="factors_considered_question">
          <h3>{questionText[4]}</h3>
          <div>
            (Please note we may use the anonymous quotes in our reports or
            publications)
          </div>
          <textarea
            value={factorsConsidered}
            onChange={(event) => setFactorsConsidered(event.target.value)}
          />
        </div>
        <button
          id="submit_button"
          className="basic_button"
          style={{ marginLeft: "auto", display: "block" }}
          disabled={!isSurveyComplete()}
          onClick={handleSubmit}
        >
          Proceed
        </button>
      </div>
    </div>
  );
}
