import { PageName } from "../../PageName";
import InformationLetter from "../Information Letter/InformationLetter";
import "./AboutPage.css";

// Shows the "About this Study" text for user reference
interface AboutPageProps {
  setPage: (pageName: PageName) => void;
  jwt: string | undefined;
}
export default function AboutPage(props: AboutPageProps) {
  return (
    <div id="about_page">
      <div className="card">
        <InformationLetter />
        <div className="end_buttons">
          <div
            className="basic_button"
            onClick={(event) => props.jwt? props.setPage(PageName.userhome) : props.setPage(PageName.welcome)}
          >
            Back
          </div>
        </div>
      </div>
    </div>
  );
}
