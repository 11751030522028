import { RoleName } from "../../common/RoleName";
import "./IdentifyYourRole.css";
interface IdentifyYourRoleProps {
  chosenRole: RoleName;
  setChosenRole: (role: RoleName) => void;
  onRoleChosen: () => void;
}
export default function IdentifyYourRole(props: IdentifyYourRoleProps) {
  return (
    <div className="identify_your_role">
      <div className="hero">
        <div className="choice_wrapper">
          <label
            className={`role_choice user ${"disabled"
              /*props.chosenRole === RoleName.User ? "selected" : ""*/
            }`}
          >
            <input
              type="radio"
              id="user_radio"
              name="role"
              value={RoleName.User}
              onChange={(event) =>
                props.setChosenRole(event.target.value as RoleName)
              }
              disabled
            />
            <img className="role_image" src="img/app_user_disabled.png" alt="Individual living with health condition(s)" />
            <div className="role_name_disabled">Thank you for your interest in participating in the ARIA study. We are no longer recruiting individuals living with health conditions. <br/><br/>For any questions, contact emily.rutledge@uwaterloo.ca.</div>
            <br />
          </label>

          <label
            className={`role_choice ${
              props.chosenRole === RoleName.Caregiver ? "selected" : ""
            }`}
            htmlFor="family_caregiver_radio"
          >
            <input
              type="radio"
              id="family_caregiver_radio"
              name="role"
              value={RoleName.Caregiver}
              onChange={(event) =>
                props.setChosenRole(event.target.value as RoleName)
              }
            />
            <img
              className="role_image"
              src="img/family_caregiver.png"
              alt="Family caregiver"
            />
            <div className="role_name">Family Caregiver</div>
            <br />
          </label>

          <label
            className={`role_choice ${
              props.chosenRole === RoleName.Provider ? "selected" : ""
            }`}
          >
            <input
              type="radio"
              id="health_care_provider_radio"
              name="role"
              value={RoleName.Provider}
              onChange={(event) =>
                props.setChosenRole(event.target.value as RoleName)
              }
            />
            <img
              className="role_image"
              src="img/healthcare_provider.png"
              alt="health care provider"
            />
            <div className="role_name">Health Care Provider</div>
            <br />
          </label>
        </div>

        <div className="role_descriptions">
          {props.chosenRole === RoleName.User && (
            <div className="app_user_description">
              <b>"I want to learn how to find a good app for myself."</b>
              <p>
                You are interested in using health apps for your own good.
                Please rate the quality of apps in this study based on your own
                interests, abilities, preferences, and needs.
              </p>
            </div>
          )}
          {props.chosenRole === RoleName.Caregiver && (
            <div className="family_caregiver_description">
              <b>
                "I want to learn how to find a good app for a family member that
                I care for."
              </b>
              <p>
                In this study, individuals who are the primary caregiver to a
                family member (spouse, parent, child) who has a disability are
                defined as family caregivers. You may take this role if you are
                a primary caregiver to a family member. If you choose this role,
                you will be requested to rate the quality of apps in this study
                based on the interests, abilities, preferences, and needs of
                your family member who is under your care.
              </p>
            </div>
          )}
          {props.chosenRole === RoleName.Provider && (
            <div className="healthcare_provider_description">
              <b>
                "I want to learn how to find a good app for my patients or
                clients."
              </b>
              <p>
                You may take this role if you are a regulated healthcare
                provider (e.g., physician, nurse, occupational therapist, etc.)
                Healthcare providers may be interested in finding health apps
                that are useful to their patients. If you take this role, you
                will be requested to rate the quality of health apps in this
                study based on the interests, abilities, preferences, and needs
                of your patients in general.
              </p>
            </div>
          )}
        </div>

        <div className="button_panel">
          <button
            className="basic_button"
            disabled={!props.chosenRole}
            onClick={props.onRoleChosen}
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
}
