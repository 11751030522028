import CoinvestigatorsContactInfo from "./CoinvestigatorsContactInfo";
import "./InformationLetter.css";

export default function InformationLetter() {
  return (
    <div id="InformationLetter">
      <h1 style={{ fontSize: 40 }}>INFORMATION LETTER and CONSENT FORM</h1>
      <div className="information_text">
        <h2>Study Title</h2>
        <p>
          Usability and reliability of the web version of the Alberta Rating
          Index for Apps (ARIA)
        </p>

        <h2>Research Investigators</h2>
        <ul style={{ listStyleType: "none" }}>
          <li>
            <b>Dr. Lili Liu</b>
          </li>
          <li>
            <a href="mailto:lili.liu@uwaterloo.ca?subject=ARIA Website Questions">
              lili.liu@uwaterloo.ca
            </a>
          </li>
        </ul>
        <ul style={{ listStyleType: "none" }}>
          <li>
            <b>Dr. Antonio Miguel Cruz</b>
          </li>
          <li>
            <a href="mailto:amcruz@uwaterloo.ca?subject=ARIA Website Questions">
              amcruz@uwaterloo.ca
            </a>
          </li>
        </ul>

        <h2>Background</h2>
        <ul>
          <li>
            There are more than 300,000 mobile health applications available to
            the public.
          </li>
          <li>
            As the sale of mobile health apps is not regulated, there are
            minimal safeguards to protect users from apps with low quality or
            harmful content.
          </li>
          <li>
            We have developed a paper-based assessment tool, Alberta Rating
            Index for Apps (ARIA), for users to rate the quality of mobile
            health apps.
          </li>
          <li>
            As part of this project we are developing a web version of the index
            (ARIA) to make it accessible to greater number of people.
          </li>
        </ul>

        <h2>Purpose</h2>
        <ul>
          <li>
            To see if the ARIA-online is reliable, and if the website for online
            rating is acceptable for use by three groups of users: health care
            providers, family caregivers, and individuals with a chronic health
            condition.
          </li>
        </ul>

        <h2>To participate, you must meet the following criteria</h2>
        <ul>
          <li>Be 18 years or older</li>
          <li>Live in Canada</li>
          <li>
            Use a smart phone or tablet regularly (at least once a week) that
            works on an iOS (Apple) or Android system
          </li>
          <li>
            Have access to and be able to use a personal computer and the
            internet
          </li>
          <li>Belong to at least one of the following groups:</li>
          <ul>
            <li>Health care professional</li>
            <li>Family caregivers</li>
            <li>Individuals living with one or more health conditions</li>
          </ul>
        </ul>

        <h2>Research Proceedures</h2>
        <ol>
          <li>
            Using your personal computer, you will visit the ARIA website
            (https://aria.watgamer.uwaterloo.ca) and create an account. Next,
            you will complete a short demographic survey (5 to 10 minutes).
          </li>
          <li>
            You will download two free mobile health apps and use each app for
            10 to 20 minutes.
          </li>
          <li>
            You will rate the quality of each of the two apps on the ARIA
            website. Click the “rate this app” button for instructions. The
            amount of time spent to rate each app varies for each participant,
            between 15 to 30 minutes. The index questions will be about how easy
            it is to use the apps or if the app works with no errors
          </li>
          <li>
            We will send you an email within three weeks asking you to repeat
            the rating for each app. We will compare your second rating with the
            first rating to investigate if the responses are consistent over the
            short period of time. This will help us to determine the reliability
            of ARIA.
          </li>
          <li>
            Finally, complete a usability questionnaire (10 items) about the
            ARIA website. This will conclude your participation in the study.
          </li>
        </ol>

        <h2>Benefits</h2>
        <ul>
          <li>
            Participation in this study will not provide any direct benefits to
            the participants
          </li>

          <li>
            You may learn about features that make a good mobile health
            application.
          </li>

          <li>
            You may learn how to rate the quality of mobile heath applications.
          </li>

          <li>
            The study will benefit the scientific community as it will expand
            our knowledge about evaluating the quality of mobile health apps.
          </li>
        </ul>

        <h2>Risks</h2>
        <ul>
          <li>
            When information is transmitted over the internet privacy cannot be
            guaranteed. There is always a risk your responses may be intercepted
            by a third party (e.g., government agencies, hackers).
          </li>
          <li>
            To protect your data, University of Waterloo researchers will not
            collect or use internet protocol (IP) addresses or other information
            which could link your participation to your computer or electronic
            device without first informing you.
          </li>
        </ul>

        <h2>Honorarium</h2>
        <ul>
          <li>
            Participants can receive up to two Amazon gift cards worth a total
            of $25. You will receive $10 for the first rating of both apps and
            $15 for the second rating of both apps and the usability survey. We
            will email you the gift cards once each task has been submitted
          </li>
          <li>
            The amount received is taxable. It is your responsibility to report
            this amount for income tax purposes.
          </li>
        </ul>

        <h2>Voluntary Participation</h2>
        <ul>
          <li>Your participation in this study is voluntary.</li>
          <li>
            You do not have to answer questions or participate if you feel
            uncomfortable and you can stop taking part at any time of the study.
          </li>
          <li>
            If you choose to withdraw from the study, you will receive
            renumeration to the point of withdrawal in the study. In other
            words, if you rate both apps in the first rating and choose to
            withdraw following that, you will still receive a $10 gift card for
            your participation. If you rate both apps in the second rating and
            the System Usability Survey and choose to withdraw following that,
            you will receive another $15 gift card for your participation.
          </li>
          <li>
            You can withdraw from the study anytime up to seven days after you
            submit the last questionnaire. After the time, your data will have
            been entered into the analysis and it is not possible for us to
            identify your data in order to remove them from the study.
          </li>
          <li>
            To withdraw from the study, you will need to email Antonio Miguel
            Cruz (amcruz@uwaterloo.ca) or Christine Daum (cdaum@uwaterloo.ca)
            and inform them about your decision. The co-investigator will then
            delete your account and remove all the data that is stored on the
            website, including any questionnaires saved but not submitted. We
            will not use any questionnaire that has not been entered into
            analysis.
          </li>
          <li>
            We will also ensure that you will receive an honorarium following
            your withdrawal that is correlated to your degree of participation,
            whether it be the first or second set of ratings completed. If you
            wish to withdraw during the survey, simply exit the website tab and
            email Antonio Miguel Cruz {" "}
            <a href="mailto:amcruz@uwaterloo.ca?subject=ARIA Withdrawal Notice">
              (amcruz@uwaterloo.ca)
            </a>{" "}
            or Christine Daum {" "}
            <a href="mailto:cdaum@uwaterloo.ca?subject=ARIA Withdrawal Notice">
              (cdaum@uwaterloo.ca)
            </a>{" "}
            and inform them about your decision. The gift card that you’re
            entitled to will be emailed to within 5 business days.
          </li>
        </ul>

        <h2>Confidentiality & Anonymity</h2>
        <p>
          The researchers will present the findings at a scientific conference
          or use them to write a paper for a scientific journal. We will not use
          any information that identifies who you are in our presentations or
          publications. We also use password protected and encrypted computers.
        </p>

        <p>
          Your participation will be considered confidential. Identifying
          information will be removed from the data that is collected and stored
          separately. Your name will not appear in any paper or publication
          resulting from this study, however, please note that anonymous
          quotations from your open-ended responses may be used. Collected data
          will be securely stored on a password protected computer at the
          University of Waterloo locations for a minimum of 7 years. Please note
          that once all identifying information has been removed, the dataset
          may be shared publicly (e.g. in data repositories). Sharing the data
          will allow other researchers to verify results and avoid duplicating
          research.
        </p>

        <h2>Has the study received ethics clearance?</h2>
        <p>
          This study has been reviewed and received ethics clearance through a
          University of Waterloo Research Ethics Committee (ORE# 42264). If you
          have questions for the Committee, contact the Office of Research
          Ethics, at 1-519-888-4567 ext. 36005 or{" "}
          <a href="mailto:ore-ceo@uwaterloo.ca">ore-ceo@uwaterloo.ca</a>
        </p>

        <h2>
          Who should I contact if I have questions regarding my participation in
          the study?
        </h2>
        <p>
          If you have any questions regarding this study, or would like
          additional information to assist you in reaching a decision about
          participation, please contact Lili Liu at 1-519-888-4567 x32126 or by
          email at{" "}
          <a href="mailto:lili.liu@uwaterloo.ca?subject=ARIA Survey Questions">
            lili.liu@uwaterloo.ca
          </a>
        </p>

        <section>
          <CoinvestigatorsContactInfo />
        </section>
      </div>
    </div>
  );
}
