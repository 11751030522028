import "./LogInDialog.css";
import React, { useState } from "react";
import { PageName } from "../../PageName";
import LogInResponseData from "../../common/LogInResponseData";

interface LogInDialogProps {
  serverURL: string;
  //setJWT: (jwt: string) => void;
  attemptLogIn: (email: string, password: string) => Promise<LogInResponseData>;
  setPage: (page: PageName) => void;
}
export default function LogInDialog(props: LogInDialogProps) {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorText, setErrorText] = useState<string>("");

  async function handleLogInClicked(event: React.MouseEvent<HTMLButtonElement>){    
      event.preventDefault();
      const result = await props.attemptLogIn(email, password);
      if(result?.errors?.email){
        setErrorText(result.errors?.email)
      }

      if(result?.errors?.password){
        setErrorText(result.errors?.password)
      }

      if(result?.errors?.other){
        setErrorText(result.errors?.other)
      }
  }

  return (
    <div id="log_in_dialog">
      <form
        className="dialog_box padded"
        style={{
          display: "grid",
          gridTemplateRows: "repeat(4, auto)",
          rowGap: "0.5em",
          padding: "3em",
        }}
      >
        <h1>Log In</h1>
        <input
          id="email_input"
          type="text"
          placeholder="E-mail"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />

        <input
          id="password_input"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />

        <div id="error_text" className="errorText">
          {errorText}
        </div>
        <button
          id="log_in_button"
          className="basic_button"
          onClick={handleLogInClicked}
        >
          Log In
        </button>
        {/* <div>Don't have an account? <span className="link" onClick={event => props.setPage(PageName.register)}>Sign Up</span></div> */}
        <div className="spacer"></div>

        <div style={{textAlign:"center"}}>Forgot your password?</div>
        <div className="link" style={{textAlign:"center"}} onClick={event => props.setPage(PageName.passwordreset)}>Reset your password</div>
      </form>
    </div>
  );
}
