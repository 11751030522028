export enum QuestionCategory {
    Purpose = "Purpose",
    Trustworthiness = "Trustworthiness",
    Privacy = "Privacy",
    Affordability = "Affordability",
    Security = "Security",
    EaseOfUse = "Ease of Use",
    Functionality = "Functionality",
    TargetUsers = "Target Users",
    UsefulnessAndSatisfaction = "Usefulness And Satisfaction",
    Overall = "Overall"
}