interface ConsentStatementProps {
  jwt: string | undefined;
}

export default function ConsentStatement(props: ConsentStatementProps) {
  return (
    <div id="ConsentStatement" className="padded">
      <h1>Consent Statement</h1>
      <p>
        By providing your consent, you are not waiving your legal rights or
        releasing the investigator(s) or involved institution(s) from their
        legal and professional responsibilities.
      </p>
      <p>
        <b>Title of the study:</b> Usability and reliability of the web version
        of the Alberta Rating Index for Apps (ARIA)
      </p>

      <p>
        I have read the information presented in the information letter about a
        study conducted by Drs. Lili Liu and Antonio Miguel Cruz, Faculty of
        Health, University of Waterloo. I have had the opportunity to ask
        questions related to the study and have received satisfactory answers to
        my questions and any additional details. I was informed that
        participation in the study is voluntary and that I can withdraw this
        consent by informing the researcher.
      </p>
      <p>
        This study has been reviewed and received ethics clearance through a
        University of Waterloo Research Ethics Committee (ORE#42264). If you
        have questions for the committee, contact the Office of Research Ethics,
        at 1-519-888-4567 ext. 36005 or{" "}
        <a href="mailto:ore-ceo@uwaterloo.ca">ore-ceo@uwaterloo.ca</a>
      </p>

      <p>
        For all other questions contact Dr. Antonio Miguel Cruz at{" "}
        <a href="mailto:amcruz@uwaterloo.ca?subject=ARIA Survey Questions">
          amcruz@uwaterloo.ca
        </a>
        .
      </p>

      <p>
        If you agree to participate in the study click on the following link to
        go to the main page of ARIA-online and create your account. Please note
        that creating an account implies consent to participate in the study.
        Click here: (https://aria.watgamer.uwaterloo.ca)
      </p>

      {!!props.jwt && (
        <p>
          (You have already consented to participate and are already registered.
          This page is for your references.)
        </p>
      )}
      {!props.jwt && (
        <div>
          <p>
            If you agree to participate in the study, click here to create an
            account:
          </p>
          <button>Register</button>
        </div>
      )}
    </div>
  );
}
