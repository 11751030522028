import { useState, useEffect } from "react";
import "./App.css";
import "../survey.css";
import LogInDialog from "../LogInDialog/LogInDialog";
import NavBar from "../NavBar/NavBar";
import Registration from "../Registration/Registration";
import AppreciationMessage from "../AppreciationMessage/AppreciationMessage";
import ConsentStatement from "../ConsentStatement/ConsentStatement";
import SystemUsabilitySurvey from "../Surveys/SystemUsabilitySurvey/SystemUsabilitySurvey";
import UserHome from "../UserHome/UserHome";
import AdminHome from "../AdminHome/AdminHome";
import WelcomePage from "../WelcomePage/WelcomePage";
import axios, { AxiosError } from "axios";
import { PageName } from "../../PageName";
import UserDemographicQuestionnaire from "../DemographicQuestionnaires/UserDemographicQuestionnaire";
import CaregiverDemographicQuestionnaire from "../DemographicQuestionnaires/CaregiverDemographicQuestionnaire";
import ProviderDemographicQuestionnaire from "../DemographicQuestionnaires/ProviderDemographicQuestionnaire";
import { RoleName } from "../../common/RoleName";
import { JWT_STORAGE_KEY /*, ROLE_STORAGE_KEY*/ } from "../../Constants";
import { APIEndPoints } from "../../common/APIEndPoints";
import PasswordReset from "../PasswordReset/PasswordReset";
import AboutPage from "../AboutPage/AboutPage";
import SurveyTool from "../Surveys/SurveyTool";
import SurveyRecord from "../../common/SurveyRecord";
import LogInRequestHeaders from "../../common/LogInRequestHeaders";
import LogInResponseData from "../../common/LogInResponseData";
import DemographicQuestionnaireData from "../../common/DemographicQuestionnaireData";
import { HashRouter, Route, Switch } from "react-router-dom";
import ChangePassword from "../ChangePassword/ChangePassword";

let serverURL =
  process.env.REACT_APP_SERVER_URL ?? "https://aria-project-server.herokuapp.com";

// if (process.env.NODE_ENV === "development") serverURL = "http://127.0.0.1:80";

function App() {
  console.log(`App() - serverURL: ${serverURL}`);
  const [jwt, setJWT] = useState<string | undefined>(undefined);
  const [userEmail, setUserEmail] = useState<string | undefined>(undefined);
  const [role, setRole] = useState<RoleName>(RoleName.User);
  // const [surveyRecords, setSurveyRecords] = useState<SurveyRecord[]>([]);
  const [focusSurveyRecord, setFocusSurveyRecord] = useState<SurveyRecord>(
    new SurveyRecord()
  );
  const [consentToContact, setConsentToContact] = useState<boolean>(false);
  const [page, setPage] = useState<PageName>(PageName.welcome);

  useEffect(() => {
    // Do we have a jwt in local storage?
    const jwt = window.localStorage.getItem(JWT_STORAGE_KEY);
    if (jwt) {
      // If so, check with the server that it's still valid and what our chosen role is
      const cancelSource = axios.CancelToken.source();

      axios
        .get(serverURL + APIEndPoints.checkJWT, {
          cancelToken: cancelSource.token,
          headers: { jwt: jwt },
        })
        .then((res) => {
          saveCredentials(
            res.data.jwt,
            res.data.userEmail,
            res.data.role,
            res.data.consentToContact
          );
          if (res.data.role === RoleName.Admin) {
            setPage(PageName.admin);
          } else {
            setPage(PageName.userhome);
          }
        })
        .catch((reason) => {
          clearCredentials();
          console.error(reason);
        });

      return () => {
        cancelSource?.cancel("App unloading.");
      };
    }
  }, []);

  async function attemptLogIn(
    email: string,
    password: string
  ): Promise<LogInResponseData> {
    try {
      const logInRequestHeaders: LogInRequestHeaders = {
        email,
        password,
      };

      const response = await axios.get(serverURL + APIEndPoints.login, {
        headers: logInRequestHeaders,
        // withCredentials: true
      });

      // const response = await axios.get(serverURL + APIEndPoints.login, {
      //   headers: {
      //     email,
      //     password,
      //   },
      // });

      saveCredentials(
        response.data.jwt,
        response.data.userEmail,
        response.data.role,
        response.data.consentToContact
      );
      console.log("Successfully logged in");

      switch (response.data.role) {
        case RoleName.Admin:
          setPage(PageName.admin);
          break;
        default:
          setPage(PageName.userhome);
          break;
      }
      return {};
    } catch (error: unknown) {
      console.error(error);
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (!axiosError.response) {
          return { errors: { other: "Please try again in 5 minutes" } };
        }
        return axiosError.response?.data;
      }
      return { errors: { other: "Unknown error" } };
    }
  }

  function logOut() {
    clearCredentials();
  }

  function saveCredentials(
    jwt: string,
    userEmail: string,
    role: RoleName,
    consentToContact: boolean
  ) {
    setJWT(jwt);
    setUserEmail(userEmail);
    setRole(role);
    setConsentToContact(consentToContact);
    window.localStorage.setItem(JWT_STORAGE_KEY, jwt);
  }

  function clearCredentials() {
    window.localStorage.removeItem(JWT_STORAGE_KEY);
    setJWT(undefined);
    setUserEmail(undefined);
    setRole(RoleName.User);
    setConsentToContact(false);
  }

  async function attemptRegistration(
    email: string,
    password: string,
    role: RoleName,
    demographicData: DemographicQuestionnaireData
  ): Promise<{ success: boolean; emailError: string; passwordError: string }> {
    // Try to register a new user
    try {
      const ipRespose = await axios.get('https://geolocation-db.com/json/');
      const ip = ipRespose.data.IPv4;
      const res = await axios.post(serverURL + APIEndPoints.users, {
        email,
        password,
        ip,
        role,
        demographicData,
      });

      console.log(`Registration successful`);
      setJWT(res.data.jwt);
      setRole(res.data.role);
      setPage(PageName.userhome);
      return { success: true, emailError: "", passwordError: "" }; // All clear
    } catch (error: any) {
      let emailError = error.response.data.errors?.email;
      let passwordError = error.response.data.errors?.password;
      return {
        success: false,
        emailError: emailError ?? "",
        passwordError: passwordError ?? "",
      };
    }
  }

  return (
    <HashRouter>
      <div className="app_div">
        <Switch>
          <Route path="/change_password/:resetToken">
            <ChangePassword serverURL={serverURL} setPage={setPage} />
          </Route>
          <Route path="/">
            <NavBar
              serverURL={serverURL}
              jwt={jwt}
              role={role}
              //credentials={credentials}
              logOut={logOut}
              setPage={setPage}
            />
            {page === PageName.welcome && <WelcomePage setPage={setPage} />}
            {page === PageName.about && <AboutPage jwt={jwt} setPage={setPage} />}
            {page === PageName.register && (
              <Registration
                //role={role}
                //setRole={setRole}
                serverURL={serverURL}
                //setJWT={setJWT}
                attemptRegistration={attemptRegistration}
                setPage={setPage}
              />
            )}
            {page === PageName.login && (
              <LogInDialog
                serverURL={serverURL}
                attemptLogIn={attemptLogIn}
                setPage={setPage}
              />
            )}
            {page === PageName.passwordreset && (
              <PasswordReset serverURL={serverURL} setPage={setPage} />
            )}
            {page === PageName.consent && <ConsentStatement jwt={jwt} />}
            {page === PageName.demographics && (
              <div>
                <UserDemographicQuestionnaire />
                <CaregiverDemographicQuestionnaire />
                <ProviderDemographicQuestionnaire />
              </div>
            )}

            {page === PageName.userhome && (
              <UserHome
                serverURL={serverURL}
                jwt={jwt}
                email={userEmail}
                role={role}
                consentToContact={consentToContact}
                setConsentToContact={setConsentToContact}
                setPage={setPage}
                // surveyRecords={surveyRecords}
                // setSurveyRecords={setSurveyRecords}
                setFocusSurveyRecord={setFocusSurveyRecord}
              />
            )}

            {page === PageName.survey && (
              <SurveyTool
                userRole={role}
                surveyRecord={focusSurveyRecord}
                jwt={jwt}
                serverURL={serverURL}
                setPage={setPage}
                setSurveyRecord={setFocusSurveyRecord}
              />
            )}
            {page === PageName.admin && (
              <AdminHome serverURL={serverURL} jwt={jwt} />
            )}
            {page === PageName.usability && (
              <SystemUsabilitySurvey
                serverURL={serverURL}
                jwt={jwt}
                setPage={setPage}
              />
            )}
            {page === PageName.appreciation && (
              <AppreciationMessage serverURL={serverURL} jwt={jwt} />
            )}
          </Route>
        </Switch>
      </div>
    </HashRouter>
  );
}

export default App;
