import { useState } from "react";
import { PageName } from "../../PageName";
import { RoleName } from "../../common/RoleName";
import IdentifyYourRole from "../IdentifyYourRole/IdentifyYourRole";
import InformationLetter from "../Information Letter/InformationLetter";
import RegistrationDetails from "./RegistrationDetails";
import "./Registration.css";
import DemographicQuestionnaire from "../DemographicQuestionnaires/DemographicQuestionnaire";
import DemographicQuestionnaireData from "../../common/DemographicQuestionnaireData";

interface RegistrationProps {
  serverURL: string;
  attemptRegistration: (
    email: string,
    password: string,
    role: RoleName,
    demographicData: DemographicQuestionnaireData
  ) => Promise<{ success: boolean; emailError: string; passwordError: string }>;
  setPage: (pageName: PageName) => void;
}

enum SubPage {
  Information = "information",
  ContactDetails = "contact_details",
  DemographicSurvey = "demographic_survey",
  RoleChoice = "role_choice",
}

export default function Registration(props: RegistrationProps) {
  // const [hasConsented, setHasConsented] = useState(false);
  const [subPage, setSubPage] = useState<SubPage>(SubPage.Information);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVerify, setPasswordVerify] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [registrationError, setRegistrationError] = useState("");
  const [chosenRole, setChosenRole] = useState<RoleName>(RoleName.Caregiver);
  // const [detailsValidated, setDetailsValidated] = useState(false);

  async function handleDemographicSurveySubmit(demographicData: DemographicQuestionnaireData) {
    try {
      const result = await props.attemptRegistration(
        email,
        password,
        chosenRole,
        demographicData
      );

      // Success
      // props.setPage(PageName.userhome);
      setEmailError(result.emailError);
      setPasswordError(result.passwordError);
    } catch (error: any) {
      console.error(error);
    }
  }

  return (
    <div className="registration">
      <div className="hero">
        {subPage === SubPage.Information && (
          <div className="card information">
            <InformationLetter /*onConsent={() => setHasConsented(true)}*/ />
            <div className="end_button">
              <div
                className="basic_button"
                // onClick={(event) => setHasConsented(true)}
                onClick={(event) => setSubPage(SubPage.ContactDetails)}
              >
                I consent to participate
              </div>
            </div>
          </div>
        )}

        {subPage === SubPage.ContactDetails && (
          <RegistrationDetails
            serverURL={props.serverURL}
            email={email}
            setEmail={setEmail}
            emailError={emailError}
            setEmailError={setEmailError}
            password={password}
            setPassword={setPassword}
            passwordVerify={passwordVerify}
            setPasswordVerify={setPasswordVerify}
            passwordError={passwordError}
            setPasswordError={setPasswordError}
            registrationError={registrationError}
            setRegistrationError={setRegistrationError}
            // role={role}
            // setRole={setRole}
            setDetailsValidated={() => setSubPage(SubPage.RoleChoice)}
            // handleProceedClicked={handleProceedClicked}
            setPage={props.setPage}
          />
        )}

        {subPage === SubPage.RoleChoice && (
          <IdentifyYourRole
            chosenRole={chosenRole}
            setChosenRole={setChosenRole}
            onRoleChosen={() => setSubPage(SubPage.DemographicSurvey)}
            //attemptRegistration={attemptRegistration}
          />
        )}

        {subPage === SubPage.DemographicSurvey && (
          <DemographicQuestionnaire role={chosenRole} handleSubmit={handleDemographicSurveySubmit} />
        )}
      </div>
    </div>
  );
}
