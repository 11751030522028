import "./UsabilitySurveyRecordListEntry.css";

import UsabilitySurveyRecord from "../../common/UsabilitySurveyRecord";

interface UsabilityRecordListEntryProps {
  surveyRecord: UsabilitySurveyRecord;
  requestDeleteUsabilitySurveyRecord: (record: UsabilitySurveyRecord) => void;
}

export default function UsabilitySurveyRecordListEntry(
  props: UsabilityRecordListEntryProps
) {
  return (
    <div className="usability_survey_record_list_entry">
      <div>{props.surveyRecord._id}</div>
      <div>{props.surveyRecord.userID}</div>
      <button
        onClick={() =>
          props.requestDeleteUsabilitySurveyRecord(props.surveyRecord)
        }
      >
        Delete
      </button>
    </div>
  );
}
