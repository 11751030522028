import "./PasswordReset.css";
import { PageName } from "../../PageName";
import { useState } from "react";
import axios from "axios";
import { APIEndPoints } from "../../common/APIEndPoints";

interface PasswordResetProps {
  setPage: (pageName: PageName) => void;
  serverURL: string;
}

export default function PasswordReset(props: PasswordResetProps) {
  const [email, setEmail] = useState("");
  const [isPending, setIsPending] = useState(false);

  const sendPasswordReset = async () => {
    setIsPending(true);
    await axios
      .post(props.serverURL + APIEndPoints.forgotPassword, null, {
        headers: { email },
      })
      .then(() => {
        alert(
          "Successfully sent reset link! Check your inbox/spam for next steps."
        );
        setEmail("");
      })
      .catch((err) => {
        if (email === "") {
          alert("Enter an email.");
        } else if (err.response.status === "502") {
          alert("Something went horribly wrong.")
        } else {
          alert(err.response.data.error);
        }
      });
    setIsPending(false);
  };

  return (
    <div id="password_reset">
      <div className="card">
        <h1>Forgot Password?</h1>
        <p>
          Enter the email address associated with your account and we'll send
          you instructions to reset your password.
        </p>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="text"
          placeholder="E-mail"
        />
        <button onClick={sendPasswordReset} disabled={!email || isPending} className="basic_button">
          Reset Password
        </button>
      </div>
    </div>
  );
}
