export default function CoinvestigatorsContactInfo() {
  return (
    <div id="CoinvestigatorsContactInfo">
      <ul style={{ listStyleType: "none" }}>
        <li>
          <b>Antonio Miguel Cruz</b>
        </li>
        <li>Faculty of Health</li>
        <li>University of Waterloo</li>
        <li>1-780-492-1728</li>
        <li>
          <a href="mailto:amcruz@uwaterloo.ca?subject=ARIA Survey Questions">
            amcruz@uwaterloo.ca
          </a>
        </li>
      </ul>

      <ul style={{ listStyleType: "none" }}>
        <li>
          <b>Christine Daum</b>
        </li>
        <li>Faculty of Health</li>
        <li>University of Waterloo</li>
        <li>1-780-492-1728</li>
        <li>
          <a href="mailto:cdaum@uwaterloo.ca?subject=ARIA Survey Questions">
            cdaum@uwaterloo.ca
          </a>
        </li>
      </ul>
    </div>
  );
}
