import { AnswerType } from "./AnswerType";
import { QuestionCategory } from "./QuestionCategory";
import { SurveyQuestionData } from "./SurveyQuestionData";

export const ARIASurveyContent: SurveyQuestionData[] = [
  {
    // number: 1,
    category: QuestionCategory.Purpose,
    answerType: AnswerType.Sentiments,
    userText: {
      text: `The description of the app's purpose fits my goals.`,
      subtext: `You may find app descriptions under "About this app" on Google Play; or "Preview" on iTunes.`,
    },
    providerText: {
      text: `The description of the app’s purpose fits the user’s goals.`,
      subtext: `You may find app description under "About this app" on Google Play; or "Preview" on iTunes)`,
    },
  },
  {
    // number: 2,
    category: QuestionCategory.Trustworthiness,
    answerType: AnswerType.Sentiments,
    userText: {
      text: `Based on the description provided on the app store or the app developer’s website, I trust that relevant experts in the field have developed the app.`,
    },
    providerText: {
      text: `Based on the description provided on the app store or the app developer’s website, the user can trust that relevant experts in the field have developed the app.`,
    },
  },
  {
    // number: 3,
    category: QuestionCategory.Trustworthiness,
    answerType: AnswerType.Sentiments,
    userText: {
      text: `The app description includes a statement about the risks associated with using the app.`,
    },
    providerText: {
      text: `The app description includes a statement about the risks associated with using the app.`,
    },
  },
  {
    //number:4,
    category: QuestionCategory.Trustworthiness,
    answerType: AnswerType.Sentiments,
    userText: {
      text: `The app declares conflicts of interest, if any.`,
    },
    providerText: {
      text: `The app declares conflicts of interest, if any.`,
    },
  },
  {
    //number:5,
    category: QuestionCategory.Privacy,
    answerType: AnswerType.Sentiments,
    userText: {
      text: `The app has a privacy policy that explains: (1) what information is collected by the app, (2) who will have access to this information, and (3) how this information will be used.`,
      subtext: `Look for the “Privacy Policy” of the app under the app description on “App Store” for Apple products or “Play store” for Android products.`,
    },
    providerText: {
      text: `The app has a privacy policy that explains: (1) what information is collected by the app, (2) who will have access to this information, and (3) how this information will be used.`,
      subtext: `Look for the “Privacy policy” of the app under the app description on “App Store” for Apple products or “Play store” for Android products.`,
    },
  },
  {
    //number:6,
    category: QuestionCategory.Affordability,
    answerType: AnswerType.Sentiments,
    userText: {
      text: `The costs associated with using the app, including in-app purchases and subscription renewal fees, are affordable.`,
      subtext: `Go to the “App Store” for Apple products or “Play Store” for Android products to learn how much does it cost to use the app.`,
    },
    providerText: {
      text: `The costs associated with using the app, including in-app purchases and subscription renewal fees, are affordable for the user.`,
      subtext: `Go to the “App Store” for Apple products or “Play Store” for Android products to learn how much does it cost to use the app.`,
    },
  },
  {
    //number:7,
    category: QuestionCategory.Security,
    answerType: AnswerType.Sentiments,
    userText: {
      text: `The app uses at least one security measure, such as user name and password or biometric identifiers (fingerprints, face recognition), to allow users to access the app.`,
    },
    providerText: {
      text: `The app uses at least one security measure, such as user name and password or biometric identifiers (fingerprints, face recognition), to allow the user to access the app.`,
    },
  },
  {
    //number:8,
    category: QuestionCategory.Security,
    answerType: AnswerType.Sentiments,
    userText: {
      text: `The app asks for my consent if it needs to access the phone's camera, microphone, my location, my contacts, or my photos.`,
    },
    providerText: {
      text: `The app asks for the user’s consent if it needs to access the phone's camera, microphone, user’s location, contacts, or photos.`,
    },
  },
  {
    //number:9,
    category: QuestionCategory.Trustworthiness,
    answerType: AnswerType.Sentiments,
    userText: {
      text: `The app mentions the references for the health information that it provides. Examples of trustworthy references are scientific papers or websites of the governmental health organizations, universities, or not-for-profit health groups.`,
    },
    providerText: {
      text: `The app mentions the references for the health information that it provides. Examples of trustworthy references are scientific papers or websites of the governmental health organizations, universities, or not-for-profit health groups.`,
    },
  },
  {
    //number:10,
    category: QuestionCategory.EaseOfUse,
    answerType: AnswerType.Sentiments,
    userText: {
      text: `Moving from one screen of the app to another is easy for me.`,
    },
    providerText: {
      text: `Moving from one screen of the app to another would be easy for the user.`,
    },
  },
  {
    //number:11,
    category: QuestionCategory.EaseOfUse,
    answerType: AnswerType.Sentiments,
    userText: {
      text: `It is easy for me to see components of the app such as text, icons, and buttons.`,
      subtext: `Pay attention to colours and sizes.`,
    },
    providerText: {
      text: `It would be easy for the user to see components of the app such as text, icons, and buttons.`,
      subtext: `Pay attention to colours and sizes.`,
    },
  },
  {
    //number:12,
    category: QuestionCategory.EaseOfUse,
    answerType: AnswerType.Sentiments,
    userText: {
      text: `It is easy for me to understand the information provided by the app.`,
      subtext: `Pay attention to the text, graphs, tables, audio, or video.`,
    },
    providerText: {
      text: `It would be easy for the user to understand the information provided by the app.`,
      subtext: `Pay attention to the text, graphics, tables, audio, or video.`,
    },
  },
  {
    //number:13,
    category: QuestionCategory.Functionality,
    answerType: AnswerType.Sentiments,
    userText: {
      text: `The app components work correctly.`,
      subtext: `For example, it does not crash or all links work.`,
    },
    providerText: {
      text: `The app components work correctly.`,
      subtext: `For example, it does not crash or all links work.`,
    },
  },
  {
    //number:14,
    category: QuestionCategory.Functionality,
    answerType: AnswerType.Sentiments,
    userText: {
      text: `I can customize the app settings to my satisfaction.`,
      subtext: `Try to customize language, font size, font colour, background colour, reminders, and notifications.`,
    },
    providerText: {
      text: `The user would be able to customize the app settings to her/his satisfaction.`,
      subtext: `For example: language, font size, font colour, background colour, reminders, and notifications.`,
    },
  },
  {
    //number:15,
    category: QuestionCategory.TargetUsers,
    answerType: AnswerType.Sentiments,
    userText: {
      text: `The content of the app is appropriate for me considering my age, gender, education, and cultural background.`,
    },
    providerText: {
      text: `The content of the app is appropriate for the user considering her/his age, gender, education, and cultural background.`,
    },
  },
  {
    //number:16,
    category: QuestionCategory.UsefulnessAndSatisfaction,
    answerType: AnswerType.Sentiments,
    userText: {
      text: `The app can help me to achieve my goals.`,
    },
    providerText: {
      text: `The app can help the user to achieve her/his goals.`,
    },
  },
  {
    //number:17,
    category: QuestionCategory.UsefulnessAndSatisfaction,
    answerType: AnswerType.Sentiments,
    userText: {
      text: `The app is pleasing to use.`,
    },
    providerText: {
      text: `The user would find the app pleasing to use.`,
    },
  },
  {
    //number:18,
    category: QuestionCategory.UsefulnessAndSatisfaction,
    answerType: AnswerType.Sentiments,
    userText: {
      text: `I am satisfied with using the app.`,
    },
    providerText: {
      text: `The user would be satisfied with using the app.`,
    },
  },
  {
    //number:19,
    category: QuestionCategory.Overall,
    answerType: AnswerType.Stars,
    userText: {
      text: `Choose the number of stars that best represents your overall rating for quality of this app.`,
      subtext: `1 star = Worst app I have ever used; 5 stars = Best app I have ever used.`,
    },
    providerText: {
      text: `Choose the number of stars that best represents your overall rating for quality of this app.`,
      subtext: `1 star = Worst app I have ever used; 5 stars = Best app I have ever used.`,
    },
  },
];
