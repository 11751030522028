import "./UserListEntry.css";
import { RoleName } from "../../common/RoleName";

interface UserListEntryProps {
  email: string;
  id: string;
  role: RoleName;
  requestDeleteUser: (email: string) => void;
}

export default function UserListEntry(props: UserListEntryProps) {
  return (
    <div className="user_list_entry">
      <div>{props.email}</div>
      <div>{props.id}</div>
      <div>{props.role}</div>
      {props.role !== RoleName.Admin && (
        <button onClick={(e) => props.requestDeleteUser(props.email)}>
          Delete
        </button>
      )}
    </div>
  );
}
