export enum APIEndPoints{
    // Used to create/confirm/update/delete users
    user = "/api/user", // For operations on a single user
    users = "/api/users", // For registration/deletion or operations involving all users
    register = "/api/register",
    login = "/api/login",
    surveys = "/api/surveys",
    survey = "/api/survey",    
    questions = "/api/questions",
    downloadusers = "/api/downloadusers",
    downloadsurveys = "/api/downloadsurveys",   
    downloadusabilityrecords = "/api/downloadusabilityrecords", 
    checkJWT = "/api/checkjwt",
    // Used to determine if a particular combination of email/password has already been registered
    checkRegistration = "/api/checkregistration",
    usability = "/api/usability",
    consent = "/api/consent",
    usabilityIndividual = "/api/individualusability",
    forgotPassword = "/api/forgotpassword",
    changePassword = "/api/changepassword",
    scheduleUpdate = "/api/scheduleupdate",
}