import "./NavBar.css";
import React from "react";
import { PageName } from "../../PageName";
import { RoleName } from "../../common/RoleName";
interface NavBarProps {
  serverURL: string;
  jwt: string | undefined;
  role: RoleName | undefined;
  logOut: () => void;
  setPage: (pageName: PageName) => void;
}

export default function NavBar(props: NavBarProps) {
  return (
    <div className="nav_bar">
      <button
        className="aria_logo aria_blue"
        onClick={() => {
          if (!props.jwt) {
            //history.push("/" + PageNames.welcome);
            props.setPage(PageName.welcome);
          } else {
            if (props.role === RoleName.Admin) {
              //history.push("/" + PageNames.admin);
              props.setPage(PageName.admin);
            } else {
              //history.push("/" + PageNames.user);
              props.setPage(PageName.userhome);
            }
          }
        }}
      >
        ARIA
      </button>

      <button className="secondary_button" onClick={event => props.setPage(PageName.about)}>About</button>

      {!!props.jwt && (<>
        <button className="secondary_button" onClick={event => props.setPage(PageName.userhome)}>Ratings</button>
        <button
          id="log_out_button"
          className="basic_button"
          onClick={() => {
            props.logOut();
            props.setPage(PageName.welcome);
          }}
        >
          Log Out
        </button>
      </>)}
      {!props.jwt && (
        <button
          id="log_in_button"
          className="basic_button"
          onClick={() => props.setPage(PageName.login)}
        >
          Log In
        </button>
      )}
    </div>
  );
}
