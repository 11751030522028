import "./AppreciationMessage.css";
//import CoinvestigatorsContactInfo from "../Information Letter/CoinvestigatorsContactInfo";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { APIEndPoints } from "../../common/APIEndPoints";

interface AppreciationMessageProps {
  //setPage: (pageName: PageName) => void;
  serverURL: string;
  jwt: string | undefined;
}

export default function AppreciationMessage(props: AppreciationMessageProps) {
  const [constentToContact, setConsentToContact] = useState<boolean>(false);
  const [hasConfirmed, setHasConfirmed] = useState<boolean>(false);

  useEffect(() => {
    axios
      .get(props.serverURL + APIEndPoints.consent, {
        headers: { jwt: props.jwt },
      })
      .then((response) => {
        const consent = response.data.consent;
        if (consent !== null) {
          setHasConfirmed(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [props.jwt, props.serverURL]);

  function handleConsentChanged(event: React.ChangeEvent<HTMLInputElement>) {
    setConsentToContact(!constentToContact);
  }

  async function handleConfirmPressed() {
    // Record the user's consent to email contact with the server
    try {
      const data = { consentToContact: constentToContact };
      /*const response = */await axios.post(
        props.serverURL + APIEndPoints.consent,
        data,
        { headers: { jwt: props.jwt } }
      );
      setHasConfirmed(true);
    } catch (error: unknown) {
      console.error(error);
    }
  }

  return (
    <div className="appreciation_message">
      <img
        className="faculty_logo"
        src="img/uwaterloo_health_logo.jpg"
        alt="University of Waterloo Faculty of Health logo"
      />
      <p>
      Thank you for participating in the study. Your responses will help us to determine the reliability of the ARIA and the usability of the ARIA website. We will keep your identity confidential. Your name, email address, or other identifying information will not appear on any of the questionnaires. You have been assigned a unique code, by the website. This code (not your name) will be used to identify questionnaires that were completed by the same participant. No personal identifying information will appear in any form in any reports. We will publish a summary of the findings on this website when we complete the data analysis. Check this website frequently for the updates.
      </p>
      <p>
      This study has been reviewed and received ethics clearance through a University of Waterloo Research Ethics Committee (ORE#42264). If you have questions for the committee contact the Office of Research Ethics at 1-519-888-4567 ext. 36005 or ore-ceo@uwaterloo.ca
      </p>

      {!hasConfirmed && (
        <div className="email_choice">
          <p>
            If you would like to receive updates in your email, please sign up
            by checking the box below.
          </p>
          <input
            id="consent_to_email_radio"
            type="checkbox"
            checked={constentToContact}
            onChange={handleConsentChanged}
          />
          <label htmlFor="consent_to_email_radio">
            I consent to receive updates about this study by email.
          </label>
        </div>
      )}
      <p>
        If you have any questions regarding this study please contact LiLi Liu
        at 1-519-888-4567 x32126 or by email at{" "}
        <a href="mailto:lili.liu@uwaterloo.ca?subject=ARIA Survey Questions">
          lili.liu@uwaterloo.ca
        </a>{" "}
      </p>

      {!hasConfirmed && (
        <button className="basic_button" onClick={handleConfirmPressed}>
          Confirm
        </button>
      )}

      {hasConfirmed && (
        <p className="nothing_more_text">
          There is nothing more for you to do as regards this website.
        </p>
      )}
    </div>
  );
}
