// import React from "react";
// import {
//   SentimentLabels,
//   SentimentNumber,
//   SentimentNumbers,
// } from "../../common/Sentiments";
import "./RatingScaleInput.css";

interface RatingScaleInputProps {
  ratingOptions: string[];
  groupName: string;
  chosenIndex: number | null;
  choiceIndexChanged: (newIndex: number) => void;
}

export default function RatingScaleInput(props: RatingScaleInputProps) {
  const elements = props.ratingOptions.map((ratingOption, optionIndex) => {
    return (
      <div className="fancy_radio_button" key={props.groupName + optionIndex}>
        <input
          id={`radio_${props.groupName}_${ratingOption}`}
          className="choice_radio_button"
          type="radio"
          name={props.groupName}
          value={ratingOption}
          checked={props.chosenIndex === optionIndex}
          //onChange={(event) => props.choiceIndexChanged(ratingOptions.index event.target.value)}
          onChange={(event) => props.choiceIndexChanged(optionIndex)}
        />
        <label htmlFor={`radio_${props.groupName}_${ratingOption}`}>
          {props.ratingOptions[optionIndex]}
        </label>
      </div>
    );
  });

  return (
    <div className="rating_scale_input">
      {elements}
    </div>
  );
}
